import {Component, Input} from '@angular/core';
import {faFileCircleCheck, faCircleExclamation} from '@fortawesome/free-solid-svg-icons';
import { ConfigurationService } from 'src/app/services/configuration.service';
import {ApiCheckResponse, RestApiService} from "../../../services/rest-api.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ShareEmailService} from "../../../services/share-email.service";
import {Gtag} from "angular-gtag";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-check-email',
  templateUrl: './check-email.component.html',
  styleUrls: ['./check-email.component.scss']
})
export class CheckEmailComponent {
  faFileCircleCheck = faFileCircleCheck;
  faCircleExclamation = faCircleExclamation;

  @Input() domain: string = "";
  submitted = false;
  restError = true;
  result: ApiCheckResponse | undefined;
  weHaveProblems = false;
  emailFieldValue = "";
  isLoading = false;
  ConfigurationService = ConfigurationService;
  errorText: SafeHtml = "";

  constructor(private readonly api: RestApiService,
              private readonly sanitizer: DomSanitizer,
              private readonly emailShareService: ShareEmailService,
              private readonly gtag: Gtag,
              public readonly config: ConfigurationService) {
    this.resetResult();
  }


  private resetResult() {
    this.result = {
      error: -1,
      info: -1,
      softwarning: -1,
      warning: -1,
    }
  }

  submitMail() {
    this.gtag.event('check-email-submit-clicked');
    this.isLoading = true;
    this.transferEmailToOtherForm();
    if (this.emailFieldValue != null && this.emailFieldValue != "")
      this.api.checkEmail(this.emailFieldValue)
        .pipe(
          finalize(() => {
            this.isLoading = false
          })
        )
        .subscribe({
          next: value => {
            this.submitted = true;
            this.restError = false;
            this.result = value;
            this.createInformationTextAboutDanger(value);
            setTimeout(()=>{
              this.submitted = false;
            }, ConfigurationService.Config.blockSubmitInMilliseconds)
          },
          error: err => {
            this.submitted = true;
            this.restError = true;
          },
        })
  }

  private createInformationTextAboutDanger(value: ApiCheckResponse) {
    if (value.error || value.softwarning || value.warning) {
      this.weHaveProblems = true;
      let text = "<p>Ellenőrzésünk során a következőket találtuk az email címével kapcsolatban</p> <ul>"
      if(value.error){
        text+=`<li><b>${value.error} komoly probléma</b></li>`
      }
      if(value.softwarning){
        text+=`<li>${value.softwarning} hiányos/hibás beállítás</li>`
      }
      if(value.warning){
        text+=`<li>${value.warning} egyéb veszélyforrás</li>`
      }
      text+="</ul> <p><b>Az ön levélszolgáltatása nem működik megfelelően. </b> Vegye fel velünk a kapcsolatot minél hamarabb, hogy megoldjuk ezt a helyzetet!</p>" +
        "        <div class=\"d-grid d-sm-flex justify-content-center\">\n" +
        "          <a class=\"btn contact-btn btn-transition px-6 mb-2\" href=\"/contact-us\" data-aos=\"flip-right\" data-aos-duration=\"1000\"  data-aos-once=\"true\"> Kapcsolatfelvételi űrlap </a>\n" +
        "        </div>"

      this.errorText = this.sanitizer.bypassSecurityTrustHtml(text)
    } else {
      this.weHaveProblems = false;
    }
  }

  reset() {
    this.submitted = false;
    this.emailFieldValue = "";
    this.restError = false;
    this.weHaveProblems = false;
    this.resetResult();
  }

  transferEmailToOtherForm(){
    this.emailShareService.updateEmail(this.emailFieldValue);
  }
}
