import { Component } from '@angular/core';
import {
  faPhone,
  faFileExport,
  faCalendar,
  faListDots,
  faShieldAlt,
  faNetworkWired,
  faCrosshairs,
  faMedal,
  faSmile,
  faPersonThroughWindow
} from '@fortawesome/free-solid-svg-icons';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-nemspam',
  templateUrl: './nemspam.component.html',
  styleUrls: ['./nemspam.component.scss']
})
export class NemspamComponent {
  ConfigurationService = ConfigurationService;

  faPhone = faPhone;
  faFileExport = faFileExport;
  faCalendar = faCalendar;
  faListDots = faListDots;
  faShieldVirus = faShieldAlt;
  faNetworkWired = faNetworkWired;
  faCrosshairs = faCrosshairs;
  faMedal = faMedal;
  faFaceSmile = faSmile;
  faPersonThroughWindow = faPersonThroughWindow;
}
