import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {configFactory, ConfigurationService} from "./services/configuration.service";
import {GtagModule} from "angular-gtag";
import {HttpClientModule} from "@angular/common/http";
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CheckEmailComponent} from './components/app-pages/check-email/check-email.component';
import {NemspamComponent} from "./components/app-pages/nemspam/nemspam.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DeliverySupportComponent} from './components/app-pages/delivery-support/delivery-support.component';
import {ContactUsComponent} from './components/app-pages/contact-us/contact-us.component';
import {ErrorComponent} from "./components/app-pages/error/error.component";
import {PrivacyPolicyComponent} from "./components/app-pages/privacy/privacy-policy/privacy-policy.component";
import {TermsConditionsComponent} from "./components/app-pages/privacy/terms-conditions/terms-conditions.component";
import {PreloaderComponent} from "./components/layouts/preloader/preloader.component";
import {NavbarComponent} from "./components/layouts/navbar/navbar.component";
import {FooterComponent} from "./components/layouts/footer/footer.component";

@NgModule({
  declarations: [
    AppComponent,
    NemspamComponent,
    CheckEmailComponent,
    DeliverySupportComponent,
    ContactUsComponent,
    ErrorComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    PreloaderComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    GtagModule.forRoot({trackingId: 'G-8YWV4212WV', trackPageviews: true}),
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      multi: true,
      deps: [ConfigurationService],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
