<div class="banner-area banner-area-two banner-img-one">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="banner-item">
              <div class="banner-left">
                <h1>Folyamatos kézbesítéstámogatás, hogy üzleti üzenetei biztosan célba érjenek!</h1>
                <p>Email azonosságának testőrei vagyunk. Rendszeres emberi ellenőrzéssel kiegészített kézbesítés
                  támogatás: biztosra megyünk, hogy biztosra mehessen!</p>
                <a routerLink="/check-email">EMAIL ELLENŐRZÉS <i class="icofont-arrow-right"></i></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="banner-item">
              <div class="banner-right">
                <img src="assets/img/kepek/email-phone.jpg" alt="Banner"
                     style="width: 440px; height: 500px; object-fit: cover">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--todo: ideiglenesen eltáűvolítva!-->
<!--<div class="counter-area">-->
<!--  <div class="container">-->
<!--    <div class="row counter-bg">-->
<!--      <div class="col-6 col-sm-3 col-lg-3">-->
<!--        <div class="counter-item">-->
<!--          <fa-icon [icon]="faNetworkWired" class="app-icon-color app-icon-position" size="2x"></fa-icon>-->
<!--          <div class="counter-inner">-->
<!--            <h3 class="counter">3</h3>-->
<!--            <p>Háttérrendszer</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="col-6 col-sm-3 col-lg-3">-->
<!--        <div class="counter-item">-->
<!--          <fa-icon [icon]="faCrosshairs" class="app-icon-color app-icon-position" size="2x"></fa-icon>-->
<!--          <div class="counter-inner">-->
<!--            <h3><span class="counter">99</span>%</h3>-->
<!--            <p>Pontos deketálás</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="col-6 col-sm-3 col-lg-3">-->
<!--        <div class="counter-item">-->
<!--          <fa-icon [icon]="faMedal" class="app-icon-color app-icon-position" size="2x"></fa-icon>-->
<!--          <div class="counter-inner">-->
<!--            <h3><span class="counter">100</span>%</h3>-->
<!--            <p>Garancia</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="col-6 col-sm-3 col-lg-3">-->
<!--        <div class="counter-item">-->
<!--          <fa-icon [icon]="faFaceSmile" class="app-icon-color app-icon-position" size="2x"></fa-icon>-->
<!--          <div class="counter-inner">-->
<!--            <h3><span class="counter">{{ConfigurationService.Config.marketing.userCount}}</span></h3>-->
<!--            <p>Elégedett ügyfél</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="help-area help-area-two pb-70">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="help-item help-left">
          <img src="assets/img/kepek/email-loss.jpg" alt="Emailek elvesztése" style="height: 600px;
    object-fit: cover">
        </div>
      </div>

      <div class="col-lg-6">
        <div class="help-item">
          <div class="help-right">
            <h2>“Küldés” után - Így vesznek el üzenetei</h2>
            <p>
              Üzenetei számos ellenőrzésen mennek át a levelezési szolgáltatók rendszerében mielőtt egyáltalán esélyt
              kapnak a kézbesítésre. Amennyiben a szolgáltató gyanúsnak vagy megbízhatatlannak ítéli leveleit, már magát
              az üzenetküldést is megakadályozhatja. Ezek a levelek még a címzettek spam mappájában sem landolhatnak,
              mivel egyáltalán nem érik el a postafiókot.
            </p>
            <p>
              A sikeresen kiküldött üzenetek további feltételeknek kell megfeleljenek a szerencsés kézbesítés
              reményében. Spam szűrők ellenőrizhetik ezek tartalmát, a feliratkozók korábbi reakcióját vagy a küldő fél
              hírnevét, ezek alapján döntik el, hogy a kiküldött üzenetek a postafiókban vagy a levélszemétben végzik.
            </p>

            <div class="help-inner-left" style="width: 100%!important;">
              <ul>
                <li><i class="flaticon-checkmark"></i>
                  Ne a szerencsén múljon a sikeres kézbesítés, megfelelő beállításokkal megelőzheti a problémákat!
                </li>
              </ul>
            </div>


            <div style="margin-top: 20px">
              <a class="cmn-btn" routerLink="/contact-us">MEGOLDÁST SZERETNÉK</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row align-items-center help-wrap">
      <div class="col-lg-6">
        <div class="help-item">
          <div class="help-right">
            <h2>Félrevezető mutatok - A kézbesítés illúziója</h2>
            <p>
              A helyzetet tovább rontja ha abban a tudatban van, hogy levelei sikeresen elérik a feliratkozók
              postafiókját. Eközben a valóságban elveszett levelek forgalomkiesést, elégedetelen ügyfeleket
              eredményeznek vagy akár a márka reputációját is rongálhatják, a feliratkozók elvesztett bizalmáról nem is
              beszélve.
            </p>
            <p>
              A megtévesztő eredményeket a levelezési rendszerek mutatói és a pontos adatok hiánya okozza. A sikeresen
              kiküldött és a postafiókba megérkezett üzenetek száma nem ad pontos képet, mivel nem tartalmazza például a
              levélszemétbe kerülés arányát.
            </p>

            <div class="help-inner-left" style="width: 100%!important;">
              <ul>
                <li><i class="flaticon-checkmark"></i>
                  Ne hagyja, hogy levélszemétben landoljanak üzenetei, biztosítsa a kézbesítést egyenesen a “Beérkezett
                  üzenetek” mappába!
                </li>
              </ul>
            </div>
            <a class="cmn-btn" routerLink="/contact-us">MEGOLDÁST SZERETNÉK</a>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="help-item help-left">
          <img src="assets/img/kepek/chart.jpg" alt="Statisztikák">
        </div>
      </div>
    </div>

    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="help-item help-left">
          <img src="assets/img/kepek/puzzle.jpg" alt="Hiányzó puzzle" style="height:660px; object-fit:cover">
        </div>
      </div>

      <div class="col-lg-6">
        <div class="help-item">
          <div class="help-right">
            <h2>Email kézbesítés ma - minden hatodik sikertelen</h2>
            <p>
              Elveszett az üzenet vagy “csak” a levélszemétben landolt? A végeredmény szempontjából ez ugyanazt jelenti:
              a statisztikák szerint minden hatodik üzenet kézbesítése sikertelen a “Beérkezett üzenetek” közé.
            </p>
            <p>
              Üzleti üzenetei átlagosan minden hatodik esetben nem érik el ügyfelét, vásárlóját, partnerét vagy a
              feliratkozó érdeklődőt. Így ezek a szereplők nem értesülnek cége legfrissebb híreiről, ajánlatairól vagy
              olyan fontos információkról, mint az ÁSZF frissítése vagy díjcsomagok és szolgáltatások változása. Mindez
              nem csak a felhasználók és a vásárlók elvesztését, de jogi lépéseket is vonhat maga után.
            </p>

            <div class="help-inner-left" style="width: 100%!important;">
              <ul>
                <li><i class="flaticon-checkmark"></i>
                  Érje el, hogy feliratkozói közül minél többen lássák üzleti leveleit a “Beérkezett üzenetek” között.
                </li>
              </ul>
            </div>


            <div style="margin-top: 20px">
              <a class="cmn-btn" routerLink="/contact-us">MEGOLDÁST SZERETNÉK</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row align-items-center help-wrap">
      <div class="col-lg-6">
        <div class="help-item">
          <div class="help-right">
            <h2>Változó terep - Ami ma működött…</h2>
            <p>
              A modern kommunikációs platformok működése nem statikus. Változó ágazati szokások és technológiai
              fejlesztések ugyanúgy hatással vannak levélszemetet szűrő algoritmusok működésére, mint a visszaélések
              megakadályozását célzó szabályozások és a felhasználók elvárásai egy biztonságosabb levelezés után.
            </p>

            <div class="help-inner-left" style="width: 100%!important;">
              <ul>
                <li><i class="flaticon-checkmark"></i>
                  Biztosítsa, hogy üzleti levelei holnap is minél több vásárlót érjenek el, függetlenül a változó
                  szolgáltatói beállításoktól!
                </li>
              </ul>
            </div>
            <a class="cmn-btn" routerLink="/contact-us">MEGOLDÁST SZERETNÉK</a>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="help-item help-left">
          <img src="assets/img/kepek/network-servers.jpg" alt="Szerverterem"
               style="height: 558px; object-fit: cover; object-position: left;">
        </div>
      </div>
    </div>

    <div class="help-shape">
      <img src="assets/img/home-one/6.png" alt="Shape">
    </div>
  </div>
</div>

<div class="case-details-img pt-100 pb-70">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="case-details-item">
          <img src="assets/img/kepek/entrepreneur.jpg" alt="Vállalkozó" style="object-fit: cover;
    height: 553px;">
        </div>
      </div>

      <div class="col-lg-7">
        <div class="case-details-item">
          <h3>Mit tehet Ön?</h3>
          <p>
            A levelezése hatékonyságának egyszerű ellenőrzéséhez hozzon létre egy email tesztfiókot.
            Figyeljen arra, hogy ezt a tesztfiókot a saját email szolgáltatójától eltérő platformon hozza létre.
            Elegendő egy olyan ingyenes email platform is megfelelő erre a célra, mint a Google, Outlook vagy Yahoo.
          </p>
          <p>
            Most, hogy megvan az új tesztfiók, iratkozzon fel vele a saját hírlevelére vagy a cím segítségével végezzen
            el egy próbavásárlást webáruháázában,
            így azonnal kiderül, hogy az adott szolgáltató megkapja az Ön leveleit, vagy sem.
          </p>
          <p>
            <b>Fontos tudni</b>, hogy különböző szolgáltatók eltérő szűrőket alkalmaznak, ezért a tesztfiókjába
            kézbesített üzenet
            <b>még nem garancia arra, hogy minden más szolgáltatónál is sikeresen megérkezik ugyanaz a levél</b>.
            Az eltérő protokollok és beállítások hatására levelezésének megítélése akár <b>időszakosan is változhat</b>,
            ezért a most elvégzett tesztelést <b>napi szinten érdemes elvégezni</b>, ráadásul minél több szolgáltatóval.
            Könnyen előfordulhat, hogy amit az Outlook biztonságosnak ítél, azt a Google már nem…
          </p>
          <p>
            Ahogy láthatja a folyamatos ellenőrzés rendkívül időigényes folyamat, erre nyújt megoldást a NEMSPAM.
            Levelezése automatikus ellenőrzésén túl folyamatosan figyeli a konkrét technikai beállításokat és
            protokollokat is, így biztosan tudhatod, hogy üzeneteid a megfelelő helyen landolnak - a “Beérkezett
            üzenetek” mappában.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


<section class="practice-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <span>HOGYAN SEGÍTHETÜNK ÖNNEK MÉG?</span>
      <h2>További szolgáltatásaink</h2>
    </div>

    <div class="row">
      <div class="col-sm-6 col-lg-4">
        <div class="practice-item">
          <div class="practice-icon">
            <fa-icon [icon]="faPhone" class="app-icon-color" size="2x"></fa-icon>
          </div>
          <h3>Telefonos támogatás</h3>
          <p>Ügyfeleinknek a nap 24 órájában elérhető telefonos ügyfélszolgálatot biztosítunk, ahol azonnali válaszokat
            kaphatnak a levelezés beállításaival kapcsolatos kérdéseikre.</p>
          <a routerLink="/contact-us">Kapcsolat</a>
          <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
          <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
        </div>
      </div>

      <div class="col-sm-6 col-lg-4">
        <div class="practice-item">
          <div class="practice-icon">
            <fa-icon [icon]="faFileExport" class="app-icon-color" size="2x"></fa-icon>
          </div>
          <h3>Prémium jelentések</h3>
          <p>A napi ellenőrzés során észlelt hibákról részletes jelentést küldünk és ahol lehetséges hibajavítási
            megoldásokra is javaslatot teszünk.</p>
          <a routerLink="/contact-us">Kapcsolat</a>
          <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
          <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
        </div>
      </div>

      <div class="col-sm-6 col-lg-4">
        <div class="practice-item">
          <div class="practice-icon">
            <fa-icon [icon]="faPersonThroughWindow" class="app-icon-color" size="2x"></fa-icon>
          </div>
          <h3>Penetrációs teszt</h3>
          <p>Cégünk ún. <a style="border: none;
    padding: unset;" href="https://en.wikipedia.org/wiki/Penetration_test">Penetrációs és Terheléses tesztek</a> végrehajtásával is foglalkozik. Ügyfeleink számára kedvezményes lehetőséget biztosítunk a teljes rendszere védelmének és megbízhatóságának teszteléséhez.</p>
          <a routerLink="/contact-us">Kapcsolat</a>
          <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
          <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
        </div>
      </div>

      <div class="col-sm-6 col-lg-4">
        <div class="practice-item">
          <div class="practice-icon">
            <fa-icon [icon]="faCalendar" class="app-icon-color" size="2x"></fa-icon>
          </div>
          <h3>Rendszeres összefoglalók</h3>
          <p>Minden hónap végén összesítő jelentést készítünk a hónap során elvégzett ellenőrzések eredményéről, így
            könnyen nyomon követheti levelezése működését.</p>
          <a routerLink="/contact-us">Kapcsolat</a>
          <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
          <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
        </div>
      </div>

      <div class="col-sm-6 col-lg-4">
        <div class="practice-item">
          <div class="practice-icon">
            <fa-icon [icon]="faListDots" class="app-icon-color" size="2x"></fa-icon>
          </div>
          <h3>Részletesebb kimutatások</h3>
          <p>A weboldalunkon található Email Ellenőrzés funkció eredményénél jelentősen részletesebb információkat
            biztosítunk ügyfeleink számára - mindezt automatikusan, napi rendszerességgel.</p>
          <a routerLink="/contact-us">Kapcsolat</a>
          <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
          <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
        </div>
      </div>

      <div class="col-sm-6 col-lg-4">
        <div class="practice-item">
          <div class="practice-icon">
            <fa-icon [icon]="faShieldVirus" class="app-icon-color" size="2x"></fa-icon>
          </div>
          <h3>Biztonsági audit</h3>
          <p>Email azonossága védelme az elsődleges feladatunk. Közreműködő partnereink által pedig további IT
            biztonsági szolgáltatásokat is igénybe vehet jelentős kedvezménnyel. További információk a <a style="border: none;
    padding: unset;" href="hardcoreit.com">hardcoreit.com</a> oldalon.</p>
          <a routerLink="/contact-us">Kapcsolat</a>
          <img class="practice-shape-one" src="assets/img/home-one/7.png" alt="Shape">
          <img class="practice-shape-two" src="assets/img/home-one/8.png" alt="Shape">
        </div>
      </div>
    </div>
  </div>
</section>
