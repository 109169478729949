<div class="page-title-area title-img-one">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-text">
        <h2>Email ellenőrzés</h2>
      </div>
    </div>
  </div>
</div>
<div style="margin-bottom: 50px; padding-bottom: 100px; ">
  <div class="email-area" style="padding: 60px">
    <div class="container text-white-50">
<!--      <img src="assets/img/home-one/newsletter.png" alt="Shape">-->
      <fa-icon [icon]="faFileCircleCheck" class="app-icon-color right-corner" size="4x"></fa-icon>
      <h2>Ellenőrizze email beállításait most!
      </h2>
      <p class="text-white">
        Adjon meg egy email címet és rendszerünk elvégzi az előzetes ellenőrzést, így azonnal megtudhatja, ha problémás beállítások húzódnak meg a háttérben, melyek akadályozzák a sikeres kézbesítést.
      </p>
      <p>
        A megadott email címet rendszerünk <b>nem tárolja el,</b> megadása kizárólag az ellenőrzéshez szükséges.
      </p>

      <form class="newsletter-form">
        <input type="email" class="form-control" placeholder="Adja meg az email címét" name="EMAIL" required autocomplete="off"
               #prmEmail="ngModel"
               [(ngModel)]="emailFieldValue">
        <button class="btn contact-btn" type="button"
                [disabled]="prmEmail.invalid || submitted"
                (click)="submitMail()">Ellenőrzés</button>
      </form>
      <div *ngIf="prmEmail.errors && prmEmail.touched" class="alert alert-danger " style="max-width: 50%">
        A megadott email cím érvénytelen.
      </div>

      <div class="column justify-content-center  align-items-center" *ngIf="isLoading">
        <span style="margin-right: 15px">Egy pillanat törelmet kérünk, az email címed ellenőrzése folyamatban van... </span>
        <span style="vertical-align: sub" class="spinner-border" role="status">
            <span class="sr-only"></span>
          </span>
      </div>

      <div *ngIf="!weHaveProblems && !restError"  role="alert" id="emailSuccess">
        <div type="success" class=" alert alert-success"  style=" margin-top: 20px;">
          <h2 class="text-success" >
            Most mindent rendben találtunk!
          </h2>
          <p style="color: black!important;">
            Az ön levelezéséhez kapcsolódó beállításokat ellenőriztük, és mindent rendben találtunk.
          </p>
          <p style="color: black!important;">
            Keressen fel bennünket bizalommal, hogy biztonságban tudja leveleit hosszú távon is - <b> hiszen a folyamatosan változó protokollok és beállítások miatt a levelezése működése bármikor megváltozhat!</b>
          </p>
          <div class="d-grid d-sm-flex justify-content-center">
            <a class="btn contact-btn btn-transition px-6 mb-2" href="/kapcsolat">Kapcsolatfelvételi űrlap</a>
          </div>
        </div>
      </div>

      <div *ngIf="weHaveProblems" class="alert alert-danger " role="alert" id="emailError">
        <div type="success" class="alert alert-danger">
          <h2 class="text-danger">
            <fa-icon [icon]="faCircleExclamation"  size="1x"></fa-icon>
            Helyesen döntött, hogy ellenőrizte email címét, problémás beállításokat észleltünk!
          </h2>
          <p style="color: black!important;" [innerHTML]="errorText"></p>
        </div>
      </div>

      <div *ngIf="submitted && restError" class="alert alert-danger " role="alert" id="apiError">
        <div  class="alert alert-danger" >
          <h2 class="text-danger">
            <fa-icon [icon]="faCircleExclamation"  size="1x"></fa-icon>
            Nem sikerült az adatokat feldolgozni.</h2>
          <p style="color: black!important;" >Kérjük, vegye fel a kapcsolatot velünk, vagy írjon az alábbi email címre közvetlenül:</p>
          <div>
            <a style="background: #e6d3d3; padding: 6px;"
               href="mailto:{{ConfigurationService.Config.contactEmail}}">{{ConfigurationService.Config.contactEmail}}</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

