<section class="error-area">
    <div class="error-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="error-text">
                    <h1>404!</h1>
                    <p>Sajnáljuk, de a keresett oldal nem található.</p>
                    <span>Az ön által keresett oldal nem található, vagy át lett helyezve.</span>
                    <a routerLink="/">Vissza a kezdőoldalra</a>
                </div>
            </div>
        </div>
    </div>
</section>
