<p>delivery-support works!</p>
<section class="expertise-area" style="padding-top: 150px">
  <div class="container" style="padding-bottom: 50px;">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="section-title text-start">
          <span>Többszintes védelem</span>
          <h2>Kézbesítéstámogatási megoldásaink</h2>
        </div>

        <div class="expertise-item">
          <ul>
            <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
              <div class="expertise-icon">
                <fa-icon [icon]="faListCheck" class="app-icon-color app-icon-position" size="2x"></fa-icon>
                <img src="assets/img/home-one/11.png" alt="Shape">
              </div>
              <h3>Előzetes audit</h3>
              <p>Levelezésének előzetes átvilágítása feltárja a javításra szoruló beállításokat. Szükséges esetben
                technikai személyzetünk módosítja és ellenőrizi ezeket a megfelelő működés biztosítása érdekében.
              </p>
            </li>

            <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
              <div class="expertise-icon">
                <fa-icon [icon]="faMagnifyingGlassChart" class="app-icon-color app-icon-position" size="2x"></fa-icon>
                <img src="assets/img/home-one/11.png" alt="Shape">
              </div>
              <h3>Napi ellenőrzés</h3>
              <p>Rendszeres ellenőrzésekkel garantáljuk, hogy a levelezést biztosító szolgáltatók háttérben elvégzett
                rendszerfrissítései ne írják felül levelezése működéséhez szükséges beállításokat. Amennyiben erre sor
                kerül, a rendszeres ellenőrzés segítségével értesülünk a változásokról és közbe tudunk lépni a megfelelő
                beállítások visszaállításához. A beállítások mellett a levelezési szolgáltatók feketelistáit is
                ellenőrizzük, így megtehetjük a szükséges lépéseket amennyiben email címei felkerülnek rá.
              </p>
            </li>

            <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
              <div class="expertise-icon">
                <fa-icon [icon]="faPhoneVolume" class="app-icon-color app-icon-position" size="2x"></fa-icon>
                <img src="assets/img/home-one/11.png" alt="Shape">
              </div>
              <h3>Email forródrót</h3>
              <p>Ennek segítségével feliratkozói azonnal jelezhetik számunkra ha gyanús levelet kapnak cége nevében. A
                forródrótos bejelentéseket azonnal közvetítjük, így időben értesítheti feliratkozóit támadás
                veszélyéről, így megőrizheti márkája hírnevét és feliratkozói töretlen bizalmát is.
              </p>
            </li>

            <li class="wow animate__animated animate__fadeInUp" data-wow-delay=".6s" style="margin-bottom: 20px">
              <div class="expertise-icon">
                <fa-icon [icon]="faUserSecret" class="app-icon-color app-icon-position" size="2x"></fa-icon>
                <img src="assets/img/home-one/11.png" alt="Shape">
              </div>
              <h3>“Tégla” email</h3>
              <p>Létrehozunk egy egyedi email címet amely beépül a levelezőlista címzettjei közé. A “tégla” email minden
                elküldött üzenet esetében ellenőrzi a sikeres kézbesítést. Ha problémát tapasztal azonnal értesíti
                technikai munkatársainkat, így egy sikertelen kézbesítés sem marad észrevétlen.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="expertise-image">
          <!--          <img src="assets/img/home-one/10.png" alt="Expertise">-->
          <img src="assets/img/kepek/campaign-creators.jpg" alt="Expertise">
        </div>
      </div>
    </div>
  </div>
</section>


<section class="blog-area blog-area-two  pt-100">
  <div class="container">

    <div class="row">
      <div class="col-sm-6 col-lg-2">

      </div>

      <div class="col-sm-6 col-lg-8">
        <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
          <a routerLink="/contact-us">
            <img src="assets/img/kepek/be-careful.png" alt="Gmail hibaüzenet">
          </a>

          <div class="blog-inner">
            <span style="font-size: 22px;">Gyanús levél</span>
            <h3>
              <a>Elveszett üzleti levelek? Alacsony megnyitási arány?</a>
            </h3>
            <p>
              Amennyiben az email beállításai nem követik az aktualitásokat, a felhasználók számára a levele ha el is
              jut az ügyfelekhez, és nem a spam fiókban randol, akkor is figyelmeztetések kíséretében jelenhetnek meg.
              Ez erősen aláássa a bizalmat, sokan gondolkodás nélkül törlik az ilyen üzeneteket a figyelmeztetés
              hatására - hiszen a csaló levelek is ilyen címkét kapnak.
            </p>
            <a class="blog-link" routerLink="/contact-us">
              Segítünk elkerülni
              <i class="icofont-simple-right"></i></a>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-2">

      </div>

    </div>


    <div class="row">
      <div class="col-sm-6 col-lg-2">

      </div>

      <div class="col-sm-6 col-lg-8">
        <div class="blog-item wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
          <a routerLink="/contact-us">
            <img src="assets/img/kepek/reports.jpg" alt="Riportolás">
          </a>

          <div class="blog-inner">
            <span style="font-size: 22px;">Riporting</span>
            <h3>
              <a>Szolgáltatásunk segítségével biztos lehet abban, hogy levelezése megfelelő beállításokkal fut és
                feliratkozói minden üzenetét megkapják - kövesse nyomon riportjainkkal.</a>
            </h3>
            <p>
              Szolgáltatásunk szerves része a folyamatos riportolás, nem csak technikai probléma esetén. Tájékoztatjuk
              levelezése helyes működéséről is, emberi ellenőrzéssel készülő havi beszámolóinkból megtudhatja az
              elvégzett technikai és üzenet kézbesítési ellenőrzések számát is.
            </p>
            <a class="blog-link" routerLink="/contact-us">
              100% Garancia
              <i class="icofont-simple-right"></i></a>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-lg-2">

      </div>

    </div>

  </div>
</section>
