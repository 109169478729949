import { Component } from '@angular/core';
import { faListCheck, faMagnifyingGlassChart, faPhoneVolume, faUserSecret} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-delivery-support',
  templateUrl: './delivery-support.component.html',
  styleUrls: ['./delivery-support.component.scss']
})
export class DeliverySupportComponent {
  faListCheck = faListCheck;
  faMagnifyingGlassChart = faMagnifyingGlassChart
  faPhoneVolume = faPhoneVolume
  faUserSecret = faUserSecret
}
