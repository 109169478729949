<div class="navbar-area fixed-top">

    <div class="mobile-nav">
        <a routerLink="/" class="logo" style="display: flex; flex-direction:row">
          <img src="assets/img/kepek/logo/3.svg" height="30" alt="Logo">
          <div class="brand-text">
            <h4>nemspam.</h4>
          </div>
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
              <a class="navbar-brand" routerLink="/" style="display: flex; flex-direction:row">
                <a class="navbar-brand" routerLink="/" style="width: 40px">
                   <img src="assets/img/kepek/logo/3.svg" height="60" alt="Logo">
                </a>
                <div class="brand-text">
                  <h4>nemspam.</h4>
                  <p>Levelezés védelem</p>
                </div>
              </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                      <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kezdőoldal</a></li>
                      <li class="nav-item"><a routerLink="/delivery-support" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kézbesítéstámogatás</a></li>
                      <li class="nav-item"><a routerLink="/check-email" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Email Ellenőrzés</a></li>
                      <li class="nav-item"><a routerLink="/contact-us" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kapcsolat</a></li>

                    </ul>

                    <div class="side-nav">
                        <a routerLink="/contact-us">Ajánlatkérés</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
