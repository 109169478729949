<div class="page-title-area title-img-two">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-text">
        <h2>Felhasználási feltételek</h2>
      </div>
    </div>
  </div>
</div>

<section class="privacy-area pt-100">
  <div class="container">
    <div class="privacy-item">
      <p>
        Kérjük, figyelmesen olvassa el az alábbi felhasználási feltételeket, mielőtt weboldalunkat használná. Az
        alábbiakban részletezzük a weboldal használatával kapcsolatos jogokat és felelősségeket.
      </p>
    </div>

    <div class="privacy-item">
      <h2>1. Elfogadás</h2>
      <p>A weboldal használatával elfogadja és kötelezőnek tartja ezeket a felhasználási feltételeket, valamint az
        adatvédelmi irányelveinket. Ha nem ért egyet ezekkel a feltételekkel, kérjük, ne használja weboldalunkat.</p>
    </div>

    <div class="privacy-item">
      <h2>2. Szellemi tulajdon</h2>
      <p>Minden tartalom és anyag, amely weboldalunkon található, beleértve, de nem kizárólagosan a szövegeket, képeket,
        logókat, grafikákat, design-t és szoftvereket, a szellemi tulajdon védelme alatt áll. Tilos ezeket a tartalmakat
        és anyagokat másolni, reprodukálni, terjeszteni vagy felhasználni kereskedelmi célokra anélkül, hogy előzetes
        írásbeli engedélyt kapnánk.</p>
    </div>

    <div class="privacy-item">
      <h2>3. Korlátozott felelősség</h2>
      <p>Weboldalunkon található információk tájékoztató jellegűek. Nem
        vállalunk felelősséget a weboldalunkon található információk pontosságáért vagy teljességéért, és semmilyen
        felelősséget nem vállalunk az Önnek vagy bármely harmadik félnek okozott károkért vagy veszteségekért, amelyek
        az információkra vagy a weboldal használatára támaszkodnak.</p>
    </div>

    <div class="privacy-item">
      <h2>4. Harmadik fél weboldalai
      </h2>
      <p>Weboldalunk harmadik fél weboldalakra is hivatkozhat vagy hivatkozásokat tartalmazhat. Ezek a hivatkozások
        kizárólag kényelmi célt szolgálnak, és nem jelentik azt, hogy támogatjuk vagy felelősséget vállalunk az ilyen
        weboldalak tartalmáért vagy tevékenységeiért. A harmadik fél weboldalainak használata kizárólag a saját
        felelősségére történik.</p>
    </div>

    <div class="privacy-item">
      <h2>5. Joghatóság és jogszabályok</h2>
      <p>Ezeket a felhasználási feltételeket a magyar jog szabályozza. Bármely vitás kérdés esetén a felek a magyar
        bíróságok kizárólagos joghatóságát ismerik el.</p>
    </div>
    <div class="privacy-item">
      <h2>6. Feltételek módosítása</h2>
      <p>Tájékoztatjuk, hogy fenntartjuk a jogot, hogy bármikor módosítsuk ezeket a felhasználási feltételeket. Az ilyen
        módosítások azonnal hatályba lépnek, amint közzétételre kerülnek weboldalunkon. Az felhasználási feltételeket
        időről időre felülvizsgáljuk és frissítjük, ezért javasoljuk, hogy rendszeresen ellenőrizze az oldalt az
        esetleges változásokkal kapcsolatban.</p>
    </div>

    <div class="privacy-item">
      <h2>7. Kapcsolatfelvétel</h2>
      <p>Amennyiben kérdése vagy észrevétele van a felhasználási feltételekkel kapcsolatban, kérjük, lépjen velünk
        kapcsolatba az alábbi elérhetőségen:</p>
      <p>
        E-mail: {{ConfigurationService.Config.contactEmail}}
      </p>

      <br/>
      <p>
        Köszönjük, hogy elfogadta a felhasználási feltételeinket és hogy weboldalunkat választotta!
      </p>
    </div>
  </div>
</section>
