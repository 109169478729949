import {Component, Input} from '@angular/core';
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ShareEmailService} from "../../../services/share-email.service";
import {Gtag} from "angular-gtag";
import {RestApiService} from "../../../services/rest-api.service";
import { ConfigurationService } from 'src/app/services/configuration.service';
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  @Input() domain: string = "";
  submitted = false;
  restError = false;
  isLoading = false;
  blockSend = false;
  ConfigurationService = ConfigurationService;

  contactForm = this.fb.group({
    name: ["", Validators.required],
    email: ["", [Validators.required, Validators.email]],
    phone: [""],
    message: [""]
  })
  mailtoLink: string;

  constructor(private readonly fb: FormBuilder,
              public readonly config: ConfigurationService,
              private readonly emailShareService: ShareEmailService,
              private readonly gtag: Gtag,
              private readonly api: RestApiService) {
    this.subscribeToEmailAddressInfo();
    this.mailtoLink = ConfigurationService.Config.contactEmail;
  }


  private subscribeToEmailAddressInfo() {
    this.emailShareService.emailOfUserAsObservable.subscribe({
      next: value => {
        this.contactForm.controls.email.patchValue(value)
      }
    })
  }

  updateMailtoLink() {
    this.mailtoLink = `${ConfigurationService.Config.contactEmail}?subject=Hirlevel%20Ellenőrző%20Kapcsolatfelvétel`;
    let body = "";

    const values = this.contactForm.getRawValue();
    if (values.name) {
      body += `Név: ${values.name} `;
    }
    if (values.email) {
      body += `%0D%0AEmail cím: ${values.email}`
    }
    if (values.phone) {
      body += `%0D%0ATelefonszám: ${values.phone}`
    }
    if (values.message) {
      body += `%0D%0AÜzenet:%0D%0A ${values.message}`
    }
    this.mailtoLink += `&body=${body}`
  }


  submitForm() {
    this.gtag.event("contact-us-form-submitted")
    this.updateMailtoLink()
    this.isLoading = true;
    if (this.contactForm.valid) {
      const values = this.contactForm.getRawValue();
      this.api.sendContactForm(values.name!, values.email!, values.phone!, values.message, [])
        .pipe(
          finalize(() => {
            this.isLoading = false
          })
        )
        .subscribe({
          next: value => {
            this.blockSend = true;
            setTimeout(() => {
              this.blockSend = false;
            }, ConfigurationService.Config.blockSubmitInMilliseconds)

            this.submitted = true;
            this.restError = false;
            this.contactForm.reset();
          },
          error: err => {
            this.blockSend = true;
            this.submitted = false;
            this.restError = true;
          }
        })
    }
  }

}
