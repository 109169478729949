import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {ConfigurationService} from "./configuration.service";

export interface emailCheck {
  email: string;
}

export interface contactForm {
  name: string;
  email: string;
  phone: string;
  message?: string;
  technicalContacts: string[] | null;
}

export interface ApiCheckResponse {
  error?: number,
  warning?: number,
  softwarning?: number,
  info?: number
}

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  constructor(private readonly httpClient: HttpClient,
              private readonly configService: ConfigurationService) {
  }

  checkEmail(email: string): Observable<ApiCheckResponse> {
    const body: emailCheck = {email}
    // return of({
    //   warning: 2,
    //   error: 4,
    //   info: 5,
    //   softwarning: 3
    // })
    // return of({})
    return this.httpClient.post<ApiCheckResponse>(ConfigurationService.Config.api.check, body)
  }

  sendContactForm(name: string, email: string, phone: string, message: string | null, technicalContacts: string[] | null) {
    const body: contactForm = {name, email, phone, technicalContacts}
    if (message !== null) {
      body.message = message
    }
    // return of({})
    return this.httpClient.post(ConfigurationService.Config.api.contact, body)
  }
}
