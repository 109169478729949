<footer style="padding: 20px">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-logo">
                      <a routerLink="/" class="logo" style="display: flex; flex-direction:row">
                        <img src="assets/img/kepek/logo/3.svg" height="30" alt="Logo" style="margin-bottom: 0!important; width: auto!important;">
                        <div class="brand-text">
                          <h4 style="color: white; margin-left: 5px">NEMSPAM.</h4>
                        </div>
                      </a>
                        <p>Levélküldő szolgáltatások ellenőrzésével, IT biztonsággal és nagyvállalait szoftverfejlesztéssel foglalkozó
                        munkatársaink a lehető legmagasabb szolgáltatásbiztonság elérést segítik önnek megteremteni. </p>
<!--                        <ul>-->
<!--                            <li><a href="https://www.youtube.com/" target="_blank"><i class="icofont-youtube-play"></i></a></li>-->
<!--                            <li><a href="https://www.facebook.com/" target="_blank"><i class="icofont-facebook"></i></a></li>-->
<!--                            <li><a href="https://www.instagram.com/" target="_blank"><i class="icofont-instagram"></i></a></li>-->
<!--                            <li><a href="https://www.twitter.com/" target="_blank"><i class="icofont-twitter"></i></a></li>-->
<!--                        </ul>-->
                    </div>
                </div>
            </div>


            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-service">
                        <h3>Menü</h3>

                        <ul>
                          <li class="nav-item"><a routerLink="/temp" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kezdőoldal</a></li>
                          <li class="nav-item"><a routerLink="/delivery-support" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kézbesítéstámogatás</a></li>
                          <li class="nav-item"><a routerLink="/check-email" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Email Ellenőrzés</a></li>
                          <li class="nav-item"><a routerLink="/contact-us" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kapcsolat</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-find">
                        <h3>Kapcsolat</h3>

                        <ul>
                            <li><i class="icofont-ui-call"></i><a href="tel:+36300742451">+36 30 074 2451</a></li>
                            <li><i class="icofont-at"></i>
                              <a href="mailto:{{ConfigurationService.Config.contactEmail}}">{{ConfigurationService.Config.contactEmail}}</a>
                          </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row">
                <div class="col-sm-7 col-lg-6">
                    <div class="copyright-item">
                        <p>© NEMSPAM. üzemeltetője a <a href="https://hardcoreit.com/" target="_blank">HARDCORE IT</a> Minden jog fenntartva.</p>
                    </div>
                </div>

                <div class="col-sm-5 col-lg-6">
                    <div class="copyright-item copyright-right">
                        <a routerLink="/terms-conditions" target="_blank">Felhasználási feltételek</a> <span>-</span>
                        <a routerLink="/privacy-policy" target="_blank">Adatvédelmi irányelvek</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
