<div class="page-title-area page-title-area-two title-image-three">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-text">
        <h2>Kapcsolatfelvételi űrlap</h2>
      </div>
    </div>
  </div>
</div>

<div class="contact-form contact-form-four pb-100">
  <div class="loaction-area">
    <div class="container">
      <div class="row location-bg">
        <div class="col-sm-6 col-lg-4">
          <div class="location-item">
            <div class="location-icon">
              <i class="flaticon-pin"></i>
            </div>
            <h3>Helyszín</h3>
            <ul>
              <li>Szolgáltatásunk szervertermekben működik, helyszni irodánk nincs.</li>
            </ul>
          </div>
        </div>

        <div class="col-sm-6 col-lg-4">
          <div class="location-item">
            <div class="location-icon">
              <i class="flaticon-call"></i>
            </div>

            <h3>Telefon</h3>

            <ul>
              <li><a href="tel:+36300742451">+36 30 074 2451</a></li>
            </ul>
          </div>
        </div>

        <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
          <div class="location-item">
            <div class="location-icon">
              <i class="flaticon-email"></i>
            </div>

            <h3>Email</h3>

            <ul>
              <li><a href="mailto:{{ConfigurationService.Config.contactEmail}}">{{ConfigurationService.Config.contactEmail}}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <form id="contactForm" [formGroup]="contactForm" (ngSubmit)="submitForm()">
      <div class="row contact-wrap">
        <div class="col-sm-6 col-lg-6">
          <div class="form-group">
            <input type="text" name="name" id="name" class="form-control"
                   formControlName="name" required data-error="Kérem, adja meg annak a személynek nevét, akit kereshetnek kollégáink."
                   placeholder="Kapcsolattartó neve*">
            <div class="custom-warning form-error"
                 *ngIf="contactForm.controls['name'].invalid  && contactForm.controls['name'].touched"
                 type="warning" >
              Kérem, adja meg annak a személynek nevét, akit kereshetnek kollégáink.
          </div>

          </div>
        </div>

        <div class="col-sm-6 col-lg-6">
          <div class="form-group">
            <input type="email" name="email" id="email" class="form-control" formControlName="email"
                   required data-error="Kérem, adja meg az email címét." placeholder="Kapcsolattartó email címe*">
            <div class="custom-warning form-error"
                       *ngIf="contactForm.controls['email'].invalid  && contactForm.controls['email'].touched"
                       type="warning" >
              Kérem, adja meg annak a személynek az email címét, akit kereshetnek kollégáink.
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-lg-6">
          <div class="form-group">
            <input type="text" name="phone_number" id="phone_number"  data-error="Kérem, adja meg a telefonszámát." class="form-control"
                   formControlName="phone" placeholder="Kapcsolattartó telefonszáma">
            <div class="custom-warning form-error"
                       *ngIf="contactForm.controls['phone'].invalid  && contactForm.controls['phone'].touched"
                       type="warning" >
              Kérem, adja meg annak a személynek a telefonszámát, akit kereshetnek kollégáink.
            </div>
          </div>
        </div>


        <div class="col-md-12 col-lg-12">
          <div class="form-group">
            <textarea name="message" class="form-control" id="message" cols="30" rows="8"
                      formControlName="message"  placeholder="Üzenet"></textarea>
          </div>
        </div>

        <div class="col-md-12 col-lg-12">
          <div class="text-center">
            <button type="submit" class="contact-btn"
                    [disabled]="contactForm.invalid || this.blockSend">
              <span *ngIf="!submitted">Kapcsolatfelvételi kérés küldése</span>
              <span *ngIf="submitted">Üzenet elküldve.</span>
              <div class="spinner-border" role="status" style="margin-left: 23px;" *ngIf="isLoading">
                <span class="sr-only"></span>
              </div>
            </button>
          </div>

          <div type="success" class="custom-success mt-2"  *ngIf="submitted">
            <h3>
              Üzenetét sikeresen rögzítettük, munkatársunk hamarosan felveszi Önnel a kapcsolatot.
            </h3>
          </div>

          <div type="success" class="custom-error mt-2"  *ngIf="restError">
            <h2>
              Az üzeneted nem tudtuk rögzíteni.
            </h2>
            <p>Sajnáljuk! Valamilyen technikai probléma lépett fel.
              Kérjük, keressen meg minket közvetlenül az alábbi email címen:
            </p>
            <div>
              <a class="contact-btn"
                 href="mailto:{{mailtoLink}}">{{ConfigurationService.Config.contactEmail}}</a>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>
</div>
