<div class="page-title-area title-img-two">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-text">
        <h2>Adatvédelmi irányelvek</h2>
      </div>
    </div>
  </div>
</div>

<section class="privacy-area pt-100">
  <div class="container">
    <div class="privacy-item">
      <p>Üdvözöljük weboldalunkon! Az alábbiakban részletesen ismertetjük az adatvédelmi irányelveinket, amelyekkel
        garantálni szeretnénk az Ön személyes adatainak védelmét és biztonságát.</p>
    </div>

    <div class="privacy-item">
      <h2>1. Személyes adatok gyűjtése és tárolása</h2>
      <p>
        Weboldalunk csak egyetlen adatot gyűjt és tárol: az Ön e-mail címét. Az e-mail címet a felhasználói élmény
        javítása és az Ön számára releváns tartalmak, információk vagy ajánlatok küldése érdekében használjuk fel. Az
        e-mail cím tárolása kizárólag az Ön hozzájárulásán alapul, és csupán az ellenőrzés időtartamáig, vagy az önnel
        való kapcsolatfelvételig tároljuk. Ezek megtörténte után az e-mail cím véglegesen és visszavonhatatlanul
        törlésre kerül az adatbázisunkból.
      </p>
    </div>

    <div class="privacy-item">
      <h2>2. Adatbiztonság</h2>
      <p>Az Ön személyes adatainak védelme számunkra kiemelten fontos. Minden szükséges technikai és szervezeti
        intézkedést megteszünk az adatok biztonságának biztosítása érdekében. Az adatokhoz csak megbízható és
        engedélyezett személyek férhetnek hozzá, és kizárólag az Ön hozzájárulása alapján kezeljük azokat.</p>
    </div>

    <div class="privacy-item">
      <h2>3. Adattovábbítás</h2>
      <p>Nem osztunk meg vagy adjuk el az Ön személyes adatait harmadik félnek, hacsak nincs erre törvényi előírás vagy
        az Ön előzetes hozzájárulása. Az adatokhoz csak azok a munkatársaink férhetnek hozzá, akiknek az adatkezeléshez
        szükségük van a munkakörük betöltéséhez.</p>
    </div>

    <div class="privacy-item">
      <h2>4. Cookie-k és webes nyomkövetés</h2>
      <p>Weboldalunk cookie-kat használhat a böngészési élmény javítása érdekében. A cookie-k kis szöveges fájlok,
        amelyeket a weboldalunk a böngészője tárol. Az Ön böngészőjében beállíthatja a cookie-k fogadását vagy
        letiltását, de kérjük, vegye figyelembe, hogy egyes funkciók és szolgáltatások esetleg nem lesznek elérhetők a
        cookie-k letiltása esetén.</p>
    </div>

    <div class="privacy-item">
      <h2>5. Jogok és kapcsolattartás</h2>
      <p>Önnek joga van az általunk tárolt személyes adataihoz hozzáférni, azok helyesbítését kérni, vagy kérni azok
        törlését. Ha bármilyen kérdése vagy kérés kapcsolódik az adatvédelemhez, kérjük, lépjen velünk kapcsolatba az
        alábbi elérhetősége</p>
      <br/>
      <p>Email címünk: {{ConfigurationService.Config.contactEmail}}</p>
      <p>Az adatvédelmi irányelveinket időről időre felülvizsgáljuk és frissítjük, ezért javasoljuk, hogy rendszeresen ellenőrizze az oldalt az esetleges változásokkal kapcsolatban.</p>
      <br/>
      <p>Köszönjük, hogy megtisztel bennünket bizalmával, és hogy weboldalunkat választotta!</p>
    </div>
  </div>
</section>
