import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NemspamComponent} from "./components/app-pages/nemspam/nemspam.component";
import {CheckEmailComponent} from "./components/app-pages/check-email/check-email.component";
import {DeliverySupportComponent} from "./components/app-pages/delivery-support/delivery-support.component";
import {ContactUsComponent} from "./components/app-pages/contact-us/contact-us.component";
import {ErrorComponent} from "./components/app-pages/error/error.component";
import {TermsConditionsComponent} from "./components/app-pages/privacy/terms-conditions/terms-conditions.component";
import {PrivacyPolicyComponent} from "./components/app-pages/privacy/privacy-policy/privacy-policy.component";

const routes: Routes = [
  {path: '', component: NemspamComponent},
  {path: 'check-email', component: CheckEmailComponent},
  {path: 'delivery-support', component: DeliverySupportComponent},
  {path: 'contact-us', component: ContactUsComponent},
  {path: 'error', component: ErrorComponent},
  {path: 'terms-conditions', component: TermsConditionsComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
